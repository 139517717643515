body {
  padding-top: 1em;
  background-image: url(http://zycienaniebiesko.com.pl/wp-content/uploads/2015/06/logo_jpg_white_duz47e1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

.custom-modal {
  padding-top: 20px;
}
 
.custom-modal-body {
  overflow-y: auto;
}

.hidden {
  display: none!important;
}

.form-active {
  display: block!important;
}

.form-inactive {
  display: none!important;
}

.panel-active {
  text-decoration: none;
  text-align: center;
  font-weight: bold;color: #029f5b;
  font-size: 18px;
}
a.panel-active:hover {
  text-decoration: none;
}

.panel-inactive {
  text-decoration: none;
  text-align: center;
  color: #666;
  font-weight: bold;
  font-size: 15px;
}

a.panel-inactive:hover {
  text-decoration: none;
}

.number-control {
  padding: 0px!important;
  text-align: center;
  width: 125%!important;
}